:root {
  --anim-duration: 3s;
  --anim-delay: 1s;
}

body {
  background-color: black;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  font-family: "Work Sans";
}

@keyframes goofy-avatar {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.avatar {
  width: 256px;
  height: 256px;
  animation: goofy-avatar var(--anim-duration) ease-in var(--anim-delay) both;
  grid-area: avatar;
  place-self: center;
}

.avatarImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid black 0.2em;
  filter: drop-shadow(0 0 0.4em black);
}

.intro {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5em;
}

@keyframes skewed-text {
  from {
    transform: skew(15deg, 15deg);
    text-shadow: 1em 1em black;
  }
  to {
    transform: skew(0deg, 0deg);
    text-shadow: 0em 0em;
  }
}

.flavor-text {
  margin: 0.5em 0;
  animation: skewed-text var(--anim-duration) ease-in var(--anim-delay) 1 both;
}

.avatar-card {
  display: grid;
  width: 100vw;
  align-items: center;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-areas: "empty avatar socials";
}

.intro p {
  margin: 0.25em 0;
  filter: drop-shadow(0 0 0.4em black);
  font-family: "Orbitron";
  font-size: 0.9em;
}

.socials {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  row-gap: 1em;
  justify-self: left;
  max-width: fit-content;
  margin: 0.5em;
  grid-area: socials;
}

@media (max-width: 400px) {
  .socials {
    justify-self: center;
    padding-left: 0;
  }
}

.icon:hover {
  transform: scale(-1, 1);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 2s ease-in calc(var(--anim-duration) + var(--anim-delay)) 1
    backwards;
}

.resume {
  margin-bottom: 0.5em;
  padding: 0.2em 0.4em;
  border-radius: 0.5em;
  background-color: black;
  color: inherit;
  text-decoration: none;
  font-size: 1.2em;
}

@media (prefers-color-scheme: dark) {
  .resume {
    background-color: black;
    color: inherit;
  }

  .resume:hover {
    background-color: white;
    color: black;
  }
}

@media (prefers-color-scheme: light) {
  .resume {
    background-color: white;
    color: inherit;
  }

  .resume:hover {
    background-color: black;
    color: white;
  }
}

@media (max-width: 800px) {
  .homepage {
    font-size: 0.8em;
  }
  .resume {
    font-size: 1.2em;
  }
}

#homepage-main {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100vw;
  height: 85vh;
}

#canvas {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100vw;
  border-radius: 0.5em;
}

.homepage {
  top: 10%;
  z-index: 2;
  height: 85vh;
}
